import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

//recapchat
import ReCAPTCHA from "react-google-recaptcha";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//user service
import contactService from "../../services/contact.service";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  subject: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

function Contact() {
  //traslate
  const { t, i18n } = useTranslation("global");
  //phone number
  const phoneNumber = "+1 (888)2081262";
  //images
  const shape = require("../../assets/img/shape/3.webp");
  const info = require("../../assets/img/contactus/info.png");
  const dev = require("../../assets/img/contactus/development.png");
  const des = require("../../assets/img/contactus/design.png");

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    document.title = "Contact";
  }, []);
  //recapchat
  const capchat = useRef(null);
  const [recapchatView, setRecapchatView] = useState(false);

  //formik
  const formik = useFormik({
    initialValues: { name: "", email: "", phone: "", subject: "", message: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      if (recapchatView === true) {
        onLogin(values);
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "please mark the recaptcha" });
      }
    },
    validationSchema: validationSchema,
  });

  const onLogin = async (values) => {
    const formattedMessage = values.message.replace(/\n/g, "<br/>");
    let data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      subject: values.subject,
      message: formattedMessage,
      key: process.env.REACT_APP_URL_SECRET_KEY_FORM,
    };
    try {
      const createContact = await contactService.createContactForm(data);
      if (
        createContact.status === 200 &&
        createContact.data.statusCode === 201
      ) {
        formik.resetForm();
        setShow(true);
      } else if (
        createContact.status === 200 &&
        createContact.data.statusCode === 404
      ) {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred! try again" });
    }
  };

  function onCapchat() {
    if (capchat.current.getValue()) {
      setRecapchatView(true);
    } else {
      setRecapchatView(false);
    }
  }
  const handleLinkClick = (event) => {
    event.preventDefault();
    const targetElement = document.querySelector("#contact_get");
    targetElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="slider-area height-100 bg-img-18 fix">
        <div className="d-table">
          <div className="dt-cell">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <div className="slide-caption style4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-title">
        <div className="contact-shape">
          <img
            loading="lazy"
            src={shape}
            alt="We Are Here To Help charlie apps"
          />
        </div>
        <h3 className="theme-color">{t("contact.subTitle")}</h3>
        <h1>{t("contact.title")}</h1>
        <p>{t("contact.titleSub")}</p>
      </div>
      <div className="mid-contact-container gray-bg mt-100 text-center pt-50 pb-50">
        <h1>{t("contact.emailUs")}</h1>
        <div className="contact-item-container">
          <div className="contact-item">
            <img
              loading="lazy"
              className="mb-30"
              src={info}
              alt="We Are Here To Help charlie apps"
            />
            <a href="mailto:info@carlieapps.com">info@CharlieApps.Com</a>
          </div>
          <div className="contact-item">
            <img
              loading="lazy"
              className="mb-30"
              src={dev}
              alt="We Are Here To Help charlie apps"
            />
            <a href="mailto:development@carlieapps.com">
              development@CharlieApps.Com
            </a>
          </div>
          <div className="contact-item">
            <img
              loading="lazy"
              className="mb-30"
              src={des}
              alt="We Are Here To Help charlie apps"
            />
            <a href="mailto:design@carlieapps.com">design@CharlieApps.Com</a>
          </div>
        </div>
      </div>

      <div className="bottom-mid-contact-container">
        <div className="contact-card">
          <h3>{t("contact.feedback")}</h3>
          <p className="mb-20">{t("contact.feedbackText")}</p>
          <a
            className="theme-color "
            href="#contact_get"
            onClick={handleLinkClick}
          >
            {t("contact.btnFeedback")}
          </a>
        </div>
        <div className="contact-card">
          <h3>{t("contact.support")}</h3>
          <p className="mb-20">{t("contact.supportText")}</p>
          <a className="theme-color " href={`tel:${phoneNumber}`}>
            {t("contact.btnSupport")}
          </a>
        </div>
      </div>

      <div className="container-fluid mt-100">
        <div className="row row-eq-height no-gutter">
          <div className="col-sm-12 col-md-6 add-image-3"></div>
          <div className="col-xs-12 col-sm-12 col-md-6 contact-bg contact-call-div">
            <div className="contact-equal height-600 d-table">
              <div className="row dt-cell text-center">
                <div className="col-xs-12 col-sm-12 mobi-mb-30">
                  <div className="bottom-contact-container">
                    <i className="pe-7s-phone"></i>
                    <h3>{t("contact.titleCall")}</h3>
                    <h2>+1 (888) 208-1262</h2>
                    <div className="RSbutton-div text-center">
                      <a
                        href={`tel:${phoneNumber}`}
                        className="btn btn-primary RSBtn contactus-button"
                      >
                        {t("contact.btnCall")}{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-form-area white-bg pt-5 pb-50" id="contact_get">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <div className="heading ">
                <h3 className="theme-color">{t("contact.getTouch")}</h3>
                <h1
                  dangerouslySetInnerHTML={{ __html: t("contact.letBuild") }}
                ></h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="contact-form text-center">
                <div id="contact-response" className="mb-15 alert"></div>
                <div className="loader">
                  <i className="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                {message ? (
                  <Alert
                    show={showAlert}
                    variant={`${message.error ? "danger" : "success"}`}
                  >
                    <p className="alert-message">{message.msg}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={() => setShowAlert(false)}
                        size="sm"
                        variant={`outline-${
                          message.error ? "danger" : "success"
                        }`}
                      >
                        Close
                      </Button>
                    </div>
                  </Alert>
                ) : null}
                <form
                  onSubmit={formik.handleSubmit}
                  className="custom-input"
                  id="contact_form"
                  method="post"
                  encType="multipart/form-data"
                  noValidate="noValidate"
                >
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <input
                        type="text"
                        className={`requiredField  ${
                          formik.errors.name && formik.touched.name
                            ? "text-danger"
                            : ""
                        }`}
                        placeholder={
                          formik.errors.name && formik.touched.name
                            ? "*" + t("contact.name") + " " + formik.errors.name
                            : t("contact.name")
                        }
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <input
                        type="email"
                        className={`requiredField  ${
                          formik.errors.email && formik.touched.email
                            ? "text-danger"
                            : ""
                        }`}
                        placeholder={
                          formik.errors.name && formik.touched.name
                            ? "*" +
                              t("contact.email") +
                              " " +
                              formik.errors.name
                            : t("contact.email")
                        }
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <input
                        type="text"
                        className={`requiredField  ${
                          formik.errors.phone && formik.touched.phone
                            ? "text-danger"
                            : ""
                        }`}
                        placeholder={
                          formik.errors.name && formik.touched.name
                            ? "*" +
                              t("contact.phone") +
                              " " +
                              formik.errors.name
                            : t("contact.phone")
                        }
                        id="phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <input
                        type="text"
                        className={`requiredField  ${
                          formik.errors.subject && formik.touched.subject
                            ? "text-danger"
                            : ""
                        }`}
                        placeholder={
                          formik.errors.subject && formik.touched.subject
                            ? "*" +
                              t("contact.subject") +
                              " " +
                              formik.errors.name
                            : t("contact.subject")
                        }
                        id="subject"
                        name="subject"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <textarea
                    className={`requiredField  ${
                      formik.errors.message && formik.touched.message
                        ? "text-danger"
                        : ""
                    }`}
                    placeholder={
                      formik.errors.subject && formik.touched.message
                        ? "*" +
                          t("contact.message") +
                          " " +
                          formik.errors.message
                        : t("contact.message")
                    }
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  <ReCAPTCHA
                    ref={capchat}
                    sitekey={process.env.REACT_APP_URL_RECAPCHAT_KEY}
                    onChange={onCapchat}
                  />

                  <button
                    disabled={!formik.isValid}
                    className="btn rt-btn2 mt-20"
                    type="submit"
                    name="submit"
                    id="contact_submit"
                    data-complete-text="Well done!"
                  >
                    {t("contact.btnForm")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className=" modal-login"
      >
        <div className="modal-content content">
          <div className="modal-body">
            <p className="text-center">
              {" "}
              The form has been sent, we will contact you soon!.
            </p>
          </div>
          <div className="modal-footer footer-login-modal  d-md-block  text-center">
            <NavLink to={"/"}>
              <button type="button" className="btn btn-sm btn-send ">
                Close
              </button>
            </NavLink>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { Contact };
